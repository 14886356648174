<template>
  <div>
    <b-card>
      <ValidationObserver ref="role" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <h5 class="mb-2 font-weight-bold">{{ $t("roles.name") }}</h5>
          <b-row>
            <b-col md="6">
              <input-form
                v-model="role.en.name"
                name="En"
                label="En"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model="role.ar.name"
                name="Ar"
                label="Ar"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col cols="12">
              <h5 class="mb-2 font-weight-bold">Role Permissions</h5>
              <b-row>
                <b-col
                  v-for="(permissionGroup, key) in systemPermissions"
                  :key="key"
                  class="mb-4"
                  md="6"
                  lg="4"
                >
                  <div class="perm-block">
                    <h6 class="font-weight-bold my-2">
                      {{ key.replace(/-/g, " ") }}
                    </h6>
                    <b-row>
                      <b-col
                        v-for="permission in permissionGroup"
                        :key="permission.id"
                        md="6"
                      >
                        <b-form-checkbox
                          v-model="role.permissions"
                          :value="{ id: permission.id }"
                          :disabled="disableStatus"
                        >
                          {{ permission.action_name.replace(/_/g, " ") }}
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import rolesServices from "../services/roles";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  data() {
    return {
      role: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        permissions: [],
      },
      permissions: null,
    };
  },
  computed: {
    translations() {
      return [
        {
          name: this.role.en.name,
          lang: "en",
        },
        {
          name: this.role.ar.name,
          lang: "ar",
        },
      ];
    },
    systemPermissions() {
      return this.transformPermissions(this.permissions);
    },
  },
  methods: {
    async getSystemPermissions() {
      const { data } = await rolesServices.getSystemPermissions();
      this.permissions = data.data;
    },
    transformPermissions(permissions) {
      return permissions?.reduce((acc, curr) => {
        acc[curr.module_name] = acc[curr.module_name] || [];
        acc[curr.module_name].push(curr);
        return acc;
      }, {});
    },
    async create() {
      rolesServices
        .create({
          translations: this.translations,
          permissions: this.role.permissions,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "administration.roles" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      rolesServices
        .update(this.id, {
          translations: this.translations,
          permissions: this.role.permissions,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "administration.roles" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    this.getSystemPermissions();
    if (this.id) {
      rolesServices.findOne(this.id).then((response) => {
        this.role.en.name = response.data.data.translations[1].name;
        this.role.ar.name = response.data.data.translations[0].name;
        this.role.permissions = response.data.data.permissions.map(
          (permission) => {
            return { id: permission.id };
          }
        );
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>


<style lang="scss">
.perm-block {
  background-color: rgba($color: #1a858d, $alpha: 0.1);
  border-radius: 10px;
  padding: 10px 16px;
  h6 {
    text-transform: capitalize;
  }
}
</style>
